import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby"
import Head from "../../head";
import Layout from "../../template/Layout"
import { BlogPaginator } from "../../components/blog/common/paginator";
import Articles from "../../components/blog/Articles";
import { WPFrontMatter } from "../../types/WPFrontMatter";
import { OWNEDMEDIA_WORDPRESS_URL } from "../../AppConst";
import { RedirectToErrorPage } from "../../utlis/404Util";

const fetchAuthorPosts = async (author, setPosts, setTotalPages, setIsLoading, setHasError) => {
  setIsLoading(true)

  // 著者の記事一覧
  fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/users/?slug=${author}`)
  .then(response => {return response.json()})
  .then(userData => {
    if(!userData.length) return RedirectToErrorPage(window);

    fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/posts/?_embed&per_page=12&author=${userData[0].id}`)
    .then(response => {
      setTotalPages(Number(response.headers.get("X-WP-TotalPages")));
      return response.json();
    })
    .then(postsData => {
      if(!postsData.length) return RedirectToErrorPage(window);

        const posts: WPFrontMatter[] = [];

        postsData.map((data, i) => {
          const postData: WPFrontMatter = {
            title: data.title.rendered,
            date: data.date,
            category: data._embedded["wp:term"][0][0].slug,
            description: "",
            author: data._embedded.author[0].name,
            thumbnail: data._embedded["wp:featuredmedia"][0].source_url,
            slug: `${data._embedded.author[0].slug}/${data.slug}`
          }
          posts.push(postData);
        })
        setPosts(posts);
        setIsLoading(false);
    })
    .catch((error) => {
      setHasError(true);
    });
  })
  .catch((error) => {
    setHasError(true);
  });
};

/**
 *@ Element & Styles
 */
const baseBreadcrumbsData: { text: string; path: string }[] = [
  { text: `BLOG`, path: `blog` },
]

export default function MemberBlogs({params, data}) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [posts, setPosts] = useState<WPFrontMatter[] | null>(null);
  const [totalPages, setTotalPages] = useState(0);
  const authorSlug = params.author

  if(!authorSlug) return null;

  const members = data.site.siteMetadata.member;
  const author: { name: string, filename: string, pathname: string } = members.find((m) => m.pathname == authorSlug);

  useEffect(() => {
    fetchAuthorPosts(authorSlug, setPosts, setTotalPages, setIsLoading, setHasError);
  }, [params.author])

  const breadcrumbsData = [
    ...baseBreadcrumbsData,
    ...[{ text: author.name, path: `/blog/${authorSlug}` }]
  ]
  const title = `${author.name}の記事一覧 | ネクストライブ株式会社`
  const description = `ブログの${author.name}の記事一覧ページです。テクノロジー、クリエイティブ、プログラミング、ビジネス、ワークスタイル、ライフスタイル、エンタメ、お知らせのブログ記事の一覧を掲載しています。`

  return (
    <Layout sidebar={true} breadcrumbsData={breadcrumbsData}>
      <Head
        title={title}
        description={description}
      />
      <Articles pageTitle={`${author.name}の記事一覧`} posts={posts} members={members} isLoading={isLoading} hasError={hasError} />
      <BlogPaginator
        basePath={`/blog/${authorSlug}`}
        prev={''}
        next={`/blog/${authorSlug}/2`}
        numberOfPages={totalPages}
        current={1}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        member {
          name
          filename
          pathname
        }
      }
    }
  }
`